import React from "react"
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@material-ui/core"
import { Link } from "gatsby-theme-material-ui"
import EmailIcon from "@material-ui/icons/Email"
// import FacebookIcon from "@material-ui/icons/Facebook"
// import TwitterIcon from "@material-ui/icons/Twitter"
import PhoneIcon from "@material-ui/icons/Phone"
import BusinessIcon from "@material-ui/icons/Business"
import { graphql, useStaticQuery } from "gatsby"

const ContactInfo = () => {
  const {
    site: {
      siteMetadata: { contactInfo: contactInfoData },
    }
  } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            contactInfo {
              # email
              phone
              address {
                line1
                line2
                line3
                city
                country
              }
              # social {
              #   facebook
              #   twitter
              # }
            }
          }
        }
      }
    `
  )

  return (
    <List dense>
      {/* <ListItem disableGutters>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText>
          <Link href={`mailto:${contactInfoData.email}`}>{contactInfoData.email}</Link>
        </ListItemText>
      </ListItem> */}
      <ListItem disableGutters>
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText>
          <Link href={`tel:${contactInfoData.phone}`}>{contactInfoData.phone}</Link>
        </ListItemText>
      </ListItem>
      <ListItem alignItems="flex-start" disableGutters>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText>
          {contactInfoData.address.line1}
          <br />
          {contactInfoData.address.line2}
          <br />
          {contactInfoData.address.line3}
          <br />
          {contactInfoData.address.city}
          <br />
          {contactInfoData.address.country}
        </ListItemText>
      </ListItem>
      {/* <ListItem disableGutters>
        <ListItemIcon>
          <SvgIcon component={FacebookIcon} />
        </ListItemIcon>
        <ListItemText>
          <Link href={`https://facebook.com/${contactInfoData.social.facebook}`}>
            fb.com/{contactInfoData.social.facebook}
          </Link>
        </ListItemText>
      </ListItem>
      <ListItem disableGutters>
        <ListItemIcon>
          <SvgIcon component={TwitterIcon} />
        </ListItemIcon>
        <ListItemText>
          <Link href={`https://twitter.com/${contactInfoData.social.twitter}`}>
            @{contactInfoData.social.twitter}
          </Link>
        </ListItemText>
      </ListItem> */}
    </List>
  )
}

export default ContactInfo