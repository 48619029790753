import React, {useState, useRef} from "react"
import PropTypes from "prop-types"
import {
  AppBar,
  Badge,
  Box,
  Drawer,
  Toolbar,
  Tooltip,
  MenuItem,
  Popper,
  Paper,
  MenuList,
} from "@material-ui/core"
import { Button, IconButton } from "gatsby-theme-material-ui"
import { makeStyles } from "@material-ui/core/styles"
import MenuIcon  from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"
import { myContext } from "../../provider"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  logo: {
    fontFamily: "Zilla Slab",
    fontSize: "1.5rem",
  },
  drawerPaper: {
    width: 240,
  },
}))

const linksData = [
  {
    label: "About",
    path: "/about",
    secondary: [
      { label: "Vision", hash: "vision" },
      { label: "Visit", hash: "visit" },
      { label: "Contact", hash: "contact" },
    ],
  },
  { label: "Catalogue", path: "/search" },
  {
    label: "Explore",
    path: "/explore",
    secondary: [
      { label: "Material", hash: "material" },
      { label: "SARRC Publications", hash: "sarrcpubs" },
      { label: "Transliteration", hash: "transliteration" },
      { label: "Resources", hash: "resources" },
    ],
  },
  { label: "Donate", path: "/donate" },
  { label: "People", path: "/people" },
  // { label: "Blog", path: "/blog"},
]

const CustomMenuItem = ({ link: { label, path, secondary }, pathname }) => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = useState(false)
  const anchorRef = useRef(null)

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }
  return (
    <>
      <Button
        key={label}
        ref={anchorRef}
        color="secondary"
        variant="text"
        to={path}
        onMouseEnter={secondary ? openMenu : null}
        onMouseLeave={closeMenu}
      >
        {label}
      </Button>
      {secondary && (
        <Popper
          open={menuOpen}
          anchorEl={anchorRef.current}
          disablePortal
          keepMounted
        >
          <Paper onMouseEnter={openMenu} onMouseLeave={closeMenu}>
            <MenuList>
              {secondary.map(({ label, hash }) => (
                <MenuItem
                  key={label}
                  component={Link}
                  href={
                    path + "/" === pathname ? `#${hash}` : `${path}#${hash}`
                  }
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  )
}

const Header = ({ siteTitle, location }) => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  return (
    <header>
      <AppBar
        variant="elevation"
        position="fixed"
        elevation={1}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Button
            color="secondary"
            variant="text"
            to="/"
            classes={{ label: classes.logo }}
          >
            SARRC
          </Button>
          <Box display={{ xs: "none", md: "flex" }}>
            {linksData.map(link => (
              <CustomMenuItem
                link={link}
                key={link.label}
                pathname={location?.pathname ? location.pathname : null}
              />
            ))}
            <myContext.Consumer>
              {context => (
                <Tooltip
                  title={`You have ${
                    context.bookmarks ? context.bookmarks.size : 0
                  } bookmarked items`}
                >
                  <IconButton
                    color="secondary"
                    to="/bookmarks"
                    edge="end"
                  >
                    <Badge
                      badgeContent={
                        context.bookmarks ? context.bookmarks.size : 0
                      }
                      color="primary"
                    >
                      <BookmarkBorderIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </myContext.Consumer>
          </Box>
          <Box display={{ xs: "block", md: "none" }}>
            <myContext.Consumer>
              {context => (
                <Tooltip
                  title={`You have ${
                    context.bookmarks ? context.bookmarks.size : 0
                  } bookmarked items`}
                >
                  <IconButton
                    color="secondary"
                    to="/bookmarks"
                    edge="end"
                  >
                    <Badge
                      badgeContent={
                        context.bookmarks ? context.bookmarks.size : 0
                      }
                      color="primary"
                    >
                      <BookmarkBorderIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </myContext.Consumer>
            <IconButton
              color="secondary"
              onClick={() => setDrawerOpen(true)}
              edge="end"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box display={{ xs: "block", md: "none" }}>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <Box display="flex" justifyContent="end">
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box pt={4}>
            {linksData.map(({ label, path }) => (
              <Button
                variant="text"
                fullWidth
                key={label}
                to={path}
              >
                {label}
              </Button>
            ))}
          </Box>
        </Drawer>
      </Box>
    </header>
  )}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
