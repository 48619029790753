import React from "react"
import Container from "@material-ui/core/Container"
import theme from "../gatsby-theme-material-ui-top-layout/theme"

const SectionWrapper = ({ children }) => (
  <section>
    <Container
      style={{
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
      }}
    >
      {children}
    </Container>
  </section>
)

export default SectionWrapper