import {
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
  Box,
} from "@material-ui/core"
import { Link } from "gatsby-theme-material-ui"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import React from "react"
import SectionWrapper from "./SectionWrapper"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import ContactInfo from "./contactInfo"

const footerTheme = createTheme(theme, {
  palette: {
    action: {
      active: theme.palette.primary.main,
    },
    text: {
      secondary: theme.palette.primary.main,
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: theme.palette.primary.main
      },
    },
  }
})

const siteLinks = [
  {
    columnTitle: "Main",
    links: [
      { label: "About", path: "/" },
      { label: "Catalogue", path: "/" },
      { label: "Updates", path: "/" },
      { label: "FAQ", path: "/" },
      { label: "Contact", path: "/" },
    ],
  },
  {
    columnTitle: "Mediums",
    links: [
      { label: "Magazines", path: "/" },
      { label: "Books", path: "/" },
      { label: "Audio", path: "/" },
      { label: "Videos", path: "/" },
      { label: "Pamphlets", path: "/" },
    ],
  },
  {
    columnTitle: "Subjects",
    links: [
      { label: "Cold War", path: "/" },
      { label: "Ayub Era", path: "/" },
      { label: "Zia Era", path: "/" },
      { label: "NAP", path: "/" },
      { label: "Communist Party", path: "/" },
    ],
  },
]

const Footer = () => {
  return (
    <ThemeProvider theme={footerTheme}>
      <Box bgcolor="#000">
        <SectionWrapper>
          <Grid container>
            <Grid item md={2}>
              <Typography variant="h4">SARRC</Typography>
              <Typography>South Asian Research & Resource Center</Typography>
            </Grid>
            <Grid item md={1} />
            <Grid item md={4}>
              <Typography variant="h4">Site Links</Typography>
              <Grid container>
                {siteLinks.map(({ columnTitle, links }) => (
                  <Grid key={columnTitle} item md={4}>
                    <List
                      dense
                      subheader={
                        <ListSubheader
                          disableSticky
                          disableGutters
                          style={{ fontWeight: 700, paddingLeft: 0 }}
                        >
                          {columnTitle}
                        </ListSubheader>
                      }
                    >
                      {links.map(({ label, path }) => (
                        <ListItem
                          key={label}
                          component={Link}
                          to={path}
                          disableGutters
                        >
                          {label}
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item md={2} />
            <Grid item md={3}>
              <Typography variant="h4">Get in touch</Typography>
              <ContactInfo />
            </Grid>
          </Grid>
        </SectionWrapper>
        <Box p={4}>
          <Typography variant="subtitle2">
            Copyright © 2021 South Asian Research & Resource Center
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Footer
